//获取商品列表
const getPointsMallList = `/gateway/hc-serve/miniapi/pointsMall/list`;
//获取兑换记录列表
const pointExchangeList = `/gateway/hc-serve/miniapi/pointsMall/userPointExchangeList`
//获取商品信息详情
const goodsDetail = `/gateway/hc-serve/miniapi/pointsMall/goodsDetailById`
//积分兑换记录详情
const exchangeDetail = `/gateway/hc-serve/miniapi/pointsMall/userPointExchangeDetail`
//积分兑换商品下单
const exchangeGoods = `/gateway/hc-serve/miniapi/pointsMall/exchangeGoods`
//积分
const pointsUrl = `/gateway/hc-accumulated/pointuser/getPointUserDetail`;
export {
    getPointsMallList,
    pointExchangeList,
    goodsDetail,
    exchangeDetail,
    exchangeGoods,
    pointsUrl,
}