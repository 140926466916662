<template>
  <common :title="title">
    <!-- 状态按钮 -->
    <template #operate-btn>
      <div class="opera" @click="dialog = true" v-show="currentIndex == 0">
        立即兑换
      </div>
    </template>
    <div class="container">
      <div class="use-point">
        可用积分：{{ pointsDetail.availableTokenPoint || 0 }}分
      </div>
      <div class="container-l">
        <img :src="detail.imageUrl || detail.skuImageUrl" alt="" />
      </div>
      <div class="container-c" v-if="currentIndex == 0">
        <p>所需积分：{{ detail.pointsPrice || 0 }}积分</p>
        <p>库存：{{ detail.stock }}</p>
        <p>可兑换次数：{{ detail.shelfSts }}次</p>
        <p style="color: #2f3a4f; font-size: 18px">产品详情</p>
        <!-- <p>领取地址：</p>
        <p>领取时间：</p> -->
        <!-- <div class="body" v-html="detail.detailContent"></div> -->
        <div class="body">
          <v-h5Mtml :content="detail.detailContent"></v-h5Mtml>
        </div>
      </div>
      <div class="container-c" v-else>
        <p>订单号：{{ detail.orderSn }}</p>
        <p>兑换数量：{{ detail.exchangeGoodsNum }}</p>
        <p>兑换时间：{{ detail.insertTime }}</p>
        <p>支付积分：{{ detail.pointsPrice || 0 }}积分</p>
      </div>
      <!-- <div class="container-r">
        <div style="color: #ed0a10">
          可用积分：{{ pointsDetail.availableTokenPoint || 0 }}分
        </div>
      </div> -->
    </div>
    <custom-dialog title="提示" v-model="dialog" @confirm="exchange">
      <div class="dialog-content">
        <p class="spec">
          <span>选择规格： </span>
          <van-radio-group v-model="submitForm.skuId" direction="horizontal">
            <van-radio
              :name="item.id"
              v-for="item in skuList"
              :key="item.id"
              @click="speChange(item)"
              >{{ item.skuName }}</van-radio
            >
          </van-radio-group>
        </p>
        <p class="number">
          <span>填写数量：</span>
          <van-stepper
            class="step"
            button-size="40px"
            v-model.number="submitForm.num"
            :max="max"
          />
        </p>
        <p><span>所需积分：</span>{{ needPoint || 0 }}积分</p>
      </div>
    </custom-dialog>
    <!-- <custom-dialog title="提示" v-model="dialog2">
      <div class="dialog-content">
        <div class="success" v-if="true">
          <div class="badge"><van-icon name="success" /></div>
          <p>物品兑换成功</p>
        </div>
        <div class="error" v-else>
          <div class="badge"><van-icon name="cross" /></div>
          <p>物品兑换失败</p>
        </div>
      </div>
    </custom-dialog> -->
  </common>
</template>

<script>
import { goodsDetail, exchangeDetail, exchangeGoods, pointsUrl } from "./api";
import common from "../components/common.vue";
import customDialog from "../components/customDialog.vue";
import Vue from "vue";
import { mapState } from "vuex";
import { Stepper, RadioGroup, Radio, Icon } from "vant";
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Stepper);
Vue.use(Icon);
export default {
  name: "myCovertDetail",
  components: {
    common,
    customDialog,
  },
  props: {},
  data() {
    return {
      title: "",
      dialog: false,
      dialog2: false,
      detail: {},
      searchParams: {
        id: "",
        // userId: "1763412296209608705",
      },
      //立即兑换的表单
      submitForm: {
        skuId: null,
        num: 0,
      },
      skuList: [],
      max: 0, //库存
      pointsDetail: {},
      currentIndex: 0,
    };
  },
  created() {},
  computed: {
    needPoint() {
      return this.detail.pointsPrice * this.submitForm.num;
    },
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  mounted() {
    const { id, title, currentIndex } = this.$route.query;
    if (![null, undefined, ""].includes(id)) {
      this.title = title;
      this.searchParams.id = id;
      this.currentIndex = currentIndex;
      if (this.currentIndex == 1) {
        this.getExchangeDetail();
      } else {
        this.getDetail();
      }
      this.getPoints();
    }
  },
  methods: {
    //获取商品详情
    getDetail() {
      let params = {
        ...this.searchParams,
        userId: this.userId || "1777286254038163457",
      };
      this.$axios
        .get(goodsDetail, {
          params,
        })
        .then((res) => {
          if (res.code == 200) {
            this.detail = res.data;
            this.skuList = res.data.skuList;
            this.submitForm.skuId = this.skuList[0].id;
            this.max = this.skuList[0].stock;
          }
        });
    },
    //获取兑换记录详情
    getExchangeDetail() {
      this.$axios
        .post(exchangeDetail, this.$qs.stringify(this.searchParams), {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          if (res.code == 200) {
            this.detail = res.data;
          }
        });
    },
    async getPoints() {
      let params = {
        userId: this.userId || "1777286254038163457",
        tenantId: this.tenantId || "P1C2A2S1D1",
      };
      let res = await this.$axios.post(pointsUrl, this.$qs.stringify(params), {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      this.pointsDetail = res.data;
    },
    //立即兑换
    exchange() {
      this.dialog2 = true;
      const params = {
        id: this.searchParams.id,
        skuId: this.submitForm.skuId,
        communityId: this.communityId || "P1C2A2S1D1V7",
        num: this.submitForm.num,
        roomId: this.roomId || this.communityId || "P1C2A2S1D1V7",
        userId: this.userId || "1777286254038163457",
        tenantId: this.tenantId || "P1C2A2S1D1",
        type: "scene",
      };
      this.$axios.post(exchangeGoods, params).then((res) => {
        if (res.code == 200) {
          this.$toast({ message: "物品兑换成功" });
          this.$router.replace({
            name: "myCovert",
            query: {
              currentIndex: 1,
            },
          });
        } else {
          this.$toast({ message: "物品兑换失败" });
        }
      });
    },

    speChange(item) {
      this.submitForm.skuId = item.id;
      this.max = item.stock;
    },
  },
};
</script>

<style scoped lang="less">
div {
  box-sizing: border-box;
}
.container {
  position: relative;
  height: 90%;
  width: 100%;
  display: flex;
  font-size: 16px;
  overflow: hidden;
  // background: #ccc;
  // align-items: flex-start;
  padding: 50px 0 32px 0;
}
.use-point {
  color: #ed0a10;
  position: absolute;
  right: 0px;
  top: 10px;
}
.container-l {
  width: 327px;
  height: 400px;
  // flex: 1.5;
  // display: flex;
  // justify-content: right;
  margin-left: 20px;
  img {
    width: 327px;
    height: 400px;
  }
}
.container-c {
  flex: 2;
  overflow-y: auto;
  color: #666666;
  padding-left: 40px;
  padding-top: 30px;
}
.container-c p {
  margin-bottom: 20px;
}
.container-r {
  flex: 1;
}
.opera {
  width: 107px;
  line-height: 50px;
  height: 50px;
  text-align: center;
  background: #1874fd;
  border-radius: 6px;
  font-size: 18px;
  color: #fff;
}
.dialog-content {
  color: #2f3a4f;
  font-size: 16px;
  span {
    display: inline-block;
    width: 100px;
  }
}
.dialog-content p {
  padding: 20px 0;
}
.dialog-content .number {
  display: flex;
  align-items: center;
  .step :deep(.van-stepper__input) {
    width: 80px;
  }
}
.dialog-content .spec {
  text-align: center;
  display: flex;
  transform: translateX(-10px);
}
.success {
  text-align: center;
}
.error {
  text-align: center;
}
.success .badge {
  width: 42px;
  height: 42px;
  background: #1cd49e;
  margin: 0 auto;
  line-height: 42px;
  color: #fff;
  border-radius: 50%;
  font-size: 26px;
}
.error .badge {
  width: 42px;
  height: 42px;
  background: #f02d32;
  margin: 0 auto;
  line-height: 42px;
  color: #fff;
  border-radius: 50%;
  font-size: 26px;
}
</style>
